<!--
 * @Descripttion:
 * @version:
 * @Author: 高涛
 * @Date: 2021-10-25 14:48:34
 * @LastEditors: 高涛
 * @LastEditTime: 2021-12-14 10:21:18
-->
<template>
  <div id="page-list">
    <!-- 面包屑导航 -->
    <Breadcrumb :breadList="breadList">
      <!-- <a-button @click="showModal" class="add-bt" type="primary">新增</a-button> -->
      <a-button class="add-bt" type="primary" @click="(()=>{showModal({editorAdd:'add',id:'',parentId:''})})">新增</a-button>
    </Breadcrumb>
    <!-- 查询 -->
    <a-form
      ref="formRef"
      layout="inline"
      :model="formState"
    >
      <a-form-item>
        <a-input v-model="formState.name" placeholder="名称" />
      </a-form-item>

      <a-form-item>
        <a-select v-model="formState.sceneCode" placeholder="版面" style="width:180px;">
          <a-select-option :value='item.code' v-for="(item, index) in sceneList" :key="index">
            {{item.name}}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item>
        <a-button @click="((e)=>{queryData()})" type="primary" html-type="submit">查询</a-button>
        <span style="margin-left:20px;"> </span>
        <a-button @click="resetForm">重置</a-button>
      </a-form-item>
    </a-form>
    <br />
    <!-- 表格 -->
    <a-table
      :columns="columns"
      :data-source="data"
      :pagination="false"
      :rowKey="(record) => record.id"
      bordered
    >
      <span slot="code" slot-scope="text,record">
        {{record.code}}
      </span>
      <span slot="idCmsScenes" slot-scope="text,record">
        <template v-for="item in record.sceneVOList">
          <div :key="item.idCmsScenes">{{item.name}}<br/>{{item.code}}</div>
        </template>
      </span>
      <span slot="status" slot-scope="text,record">
        {{ record.status===0?"停用":record.status===1?"正常":"不是0也不是1" }}
      </span>
      <div slot="action" slot-scope="record">
        <span>
          <a v-if="record.level !=3" @click="(()=>{showModal({ editorAdd:'add', id:record.id, parentId:record.parentId })})">新增</a>
          <a-divider v-if="record.level !=3" type="vertical" />
          <a @click="(()=>{showModal({ editorAdd:'edit', id:record.id, parentId:record.parentId })})">编辑</a>
          <a-divider type="vertical" />
          <a-popconfirm
            title="确定要删除此条信息吗?"
            ok-text="是"
            cancel-text="否"
            @confirm="confirm(record.id)"
            @cancel="cancel"
          >
            <a href="#">删除</a>
          </a-popconfirm>
        </span>
      </div>
    </a-table>
    <br />
    <!-- 分页 -->
    <!-- <a-pagination
      show-size-changer
      show-quick-jumper
      style="text-align: center"
      :total="total"
      :show-total="
        (total, range) => `共${total}条记录 第${range[0]}-${range[1]}条数据`
      "
      :page-size="pageSize"
      v-model="current"
      @change="onPageChange"
      @showSizeChange="onShowSizeChange"
    /> -->
    <!-- 弹窗口 -->
    <a-modal v-model="visible" :title="modalTitle" @ok="handleOk" :footer="null">
      <ColumEdit ref="refColumEdit"  @ok="handleOk"></ColumEdit>
    </a-modal>
  </div>
</template>

<script>
import Breadcrumb from '@/components/breadcrumb.vue'
import ColumEdit from './addOrEdit.vue'
export default {
  components: {
    Breadcrumb,
    ColumEdit
  },
  data () {
    return {
      modalTitle: '',
      sceneList: [],
      breadList: [
        {
          name: '栏目管理',
          url: '/content/list'
        }
      ],
      columns: [
        {
          title: '栏目名称',
          dataIndex: 'name',
          width: 150,
          ellipsis: true,
          key: 'name'
        },
        {
          title: '栏目编号',
          dataIndex: 'id',
          key: 'id',
          width: 150,
          ellipsis: true,
          scopedSlots: {
            customRender: 'code'
          }
        },
        {
          title: '状态',
          dataIndex: 'status',
          key: 'status',
          width: 100,
          ellipsis: true,
          scopedSlots: {
            customRender: 'status'
          }
        },
        // {
        //   title: '版面',
        //   dataIndex: 'idCmsScenes',
        //   key: 'idCmsScenes',
        //   scopedSlots: {
        //     customRender: 'idCmsScenes'
        //   }
        // },
        {
          title: '排序',
          dataIndex: 'sort',
          key: 'sort',
          width: 100,
          ellipsis: true
        },
        {
          title: '更新时间',
          dataIndex: 'gmtModified',
          key: 'gmtModified',
          width: 180,
          ellipsis: true
        },
        {
          title: '备注',
          dataIndex: 'memo',
          key: 'memo'
        },
        {
          title: '操作',
          key: 'action',
          width: 350,
          ellipsis: true,
          scopedSlots: {
            customRender: 'action'
          }
        }
      ],
      rules: {
        name: [{ required: true, message: 'adadsf', trigger: 'blur' }],
        region: [{ required: true, message: 'Please select Activity zone', trigger: 'change' }]
      },
      visible: false,
      formState: {
        name: '',
        sceneCode: undefined
      },
      pageTypeList: [
        {
          value: 'abc',
          label: '磊在'
        }
      ],
      data: [],
      total: 0,
      pageSize: 10,
      current: 1
    }
  },
  created () {
    this.queryData()
    this.queryScenes()
  },
  methods: {
    async queryScenes () {
      const res = await this.$store.dispatch('http', {
        api: 'cmsManageSceneList'
      })
      console.log(res, '版面列表')
      this.sceneList = res
    },
    async delColumn (id) {
      const res = await this.$store.dispatch('http', {
        api: 'navigationDelete',
        query: { id: id }
      })
      if (res) {
        this.$store.dispatch('showToast', { type: 'success', message: '删除成功' })
        this.queryData()
      }
    },
    async queryData (pageNumber) {
      // 如果不是点击的分页  而是点的查询按钮  需要修改  当前页current
      this.current = pageNumber ? this.current : 1
      console.log(this.current, this.pageSize)
      const res = await this.$store.dispatch('http', {
        api: 'navigationAllTree',
        // api: 'navagationTree',
        params: {
          ...this.formState,
          pageNo: this.current,
          pageSize: this.pageSize
        }
      })
      console.log(res)

      this.data = this.treeDone(res)
      this.total = res.length
      this.$forceUpdate()
    },
    handleFinish () {
    },
    handleFinishFailed () {
      console.log()
    },
    // 递归处理树数据 删除chiidren为空的 children属性
    treeDone (tree) {
      tree.forEach(item => {
        if (item.children && item.children.length > 0) {
          item = this.treeDone(item.children)
        } else {
          delete item.children
        }
        return item
      })
      return tree
    },
    resetForm () {
      this.formState = {
        name: '',
        sceneCode: undefined
      }
      this.queryData()
    },
    // 改变页码
    onPageChange (pageNumber, pageSize) {
      this.queryData(pageNumber)
    },
    // 改变页码
    onShowSizeChange (current, pgSize) {
      console.log(pgSize)
      this.pageSize = pgSize
      this.queryData()
    },
    // 是否确认弹窗口  方法
    confirm (id) {
      this.delColumn(id)
    },
    cancel (values) {
      console.log(values)
    },
    // 弹出窗口
    showModal ({ editorAdd, id, parentId }) {
      this.modalTitle = editorAdd === 'add' ? '新增栏目' : '编辑栏目'
      this.visible = true
      setTimeout(() => {
        this.$refs.refColumEdit.init({ editorAdd, id, parentId })
      }, 200)
    },
    handleOk (values) {
      this.visible = false
      this.queryData()
    }
  }
}
</script>

<style>
</style>
