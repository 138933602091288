<!--
 * @Descripttion:
 * @version:
 * @Author: 高涛
 * @Date: 2021-10-25 15:19:14
 * @LastEditors: 高涛
 * @LastEditTime: 2021-12-27 10:21:07
-->
<template>
  <a-form-model
    ref="ruleForm"
    :model="form"
    :rules="rules"
    :label-col="labelCol"
    :wrapper-col="wrapperCol">
        <!-- 上级栏目 -->
        <br/>
        <a-form-model-item label="版面" v-show="false">
          <a-select
            :disabled="form.level>1"
            mode="multiple"
            v-model="form.idCmsScenes"
            style="width: 100%"
            placeholder="请选择"
            @change="handleChange"
          >
            <a-select-option v-for="(item) in sceneList" :key="item.id">
              {{ item.name}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
    <a-form-model-item label="版面" v-show="false">
      <a-input value="发现" disabled/>
    </a-form-model-item>
        <a-form-model-item label="上级栏目">
          <a-tree-select
            v-model="form.parentId"
            style="width: 100%"
            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
            :tree-data="treeData"
            placeholder="Please select"
            treeNodeFilterProp="label"
            :replaceFields="{children:'children', title:'name', key:'id', value: 'id' }"
            tree-default-expand-all
          >
            <span v-if="key === '0-0-1'" slot="name" slot-scope="{ key, value }" style="color: #08c">
              Child Node1 {{ value }}
            </span>
          </a-tree-select>
        </a-form-model-item>
        <!-- 栏目名称 -->
        <a-form-model-item label="栏目名称" prop="name">
            <a-input placeholder="请输入" v-model="form.name"/>
        </a-form-model-item>
        <a-form-model-item label="栏目编号" prop="code">
            <a-input placeholder="请输入" v-model="form.code"/>
        </a-form-model-item>
        <!-- 排序 -->
        <a-form-model-item label="排序" prop="sort">
            <a-input-number placeholder="请输入" v-model="form.sort" :min="1" :max="10" />
        </a-form-model-item>
        <!-- 是否启用 -->
        <a-form-model-item label="是否启用">
            <a-radio-group name="radioGroup" v-model="form.status">
                <a-radio :value=1>启用</a-radio>
                <a-radio :value=0>不启用</a-radio>
            </a-radio-group>
        </a-form-model-item>
        <!-- 栏目描述 -->
        <a-form-item label="栏目描述">
            <a-textarea v-model="form.memo" placeholder="请输入备注" :rows="4"/>
        </a-form-item>
        <!-- 底部按钮 -->
        <a-form-item :wrapper-col="{ span: 14, offset: 6 }">
            <a-button type="primary" @click.prevent="onSubmit">提交</a-button>
            <a-button style="margin-left: 10px" @click="resetFields">取消</a-button>
        </a-form-item>
        <br/>
    </a-form-model>
</template>

<script>
export default {
  data () {
    return {
      fatherLan: {
        id: '0',
        value: '0',
        name: '无',
        children: []
      },
      sceneList: [],
      iseditorAdd: '',
      value: undefined,
      treeData: [],
      options: [
        {
          value: 'jack',
          label: 'Jack'
        },
        {
          value: 'lucy',
          label: 'Lucy'
        }
      ],
      rules: {
        name: [
          { required: true, message: '请填写栏目名称', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请填写栏目编号', trigger: 'blur' }
        ],
        sort: [
          { required: true, message: '请填写排序', trigger: 'blur' }
        ]
      },
      form: {
        id: '',
        parentId: '0',
        navigationType: 'cms',

        name: '',
        idCmsScenes: [],
        sort: undefined,
        status: '1',
        memo: ''
      },
      labelCol: {
        span: 6
      },
      wrapperCol: {
        span: 14
      }
    }
  },
  watch: {
    value (value) {
      console.log(value)
    }
  },
  created () {
    this.queryTreeData()
    this.queryScenes()
  },
  methods: {
    async queryScenes () {
      const res = await this.$store.dispatch('http', {
        api: 'cmsManageSceneList'
      })
      console.log(res, '版面列表')
      this.sceneList = res
    },
    resetForm () {
      this.form = {
        id: '',
        parentId: '0',
        navigationType: 'cms',
        name: '',
        code: this.$utils.codeGenerator('', 6),
        sort: undefined,
        status: 1,
        memo: ''
      }
    },
    async init ({ editorAdd, id, parentId }) {
      console.log(editorAdd, id, parentId, '到底有啥')
      this.queryTreeData()
      this.resetForm()
      this.iseditorAdd = editorAdd || ''
      if (editorAdd === 'add') {
        this.form.id = undefined
        this.form.parentId = id || '0'
      } else if (editorAdd === 'edit') {
        const res = await this.$store.dispatch('http', {
          api: 'navigationInfo',
          query: { id: id }
        })
        console.log(res, '查询到了什么')
        if (res) {
          let arr = []
          if (res.sceneVOList === null || res.sceneVOList === undefined) { res.sceneVOList = [] }
          for (const { idCmsScene } of res.sceneVOList) {
            arr.push(idCmsScene)
          }
          res.idCmsScenes = arr
          delete (res.sceneVOList)
          this.form = { ...res }
        }
      }
    },
    onSubmit () {
      // this.submitForm()
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.submitForm()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    async submitForm () {
      let apiStr = ''
      apiStr = this.iseditorAdd === 'add' ? 'navigationSave' : this.iseditorAdd === 'edit' ? 'navigationUpdate' : ''
      console.log(apiStr, 'apiStrapiStrapiStrapiStr')
      if (this.form.idCmsScenes === '' || this.form.idCmsScenes === null || this.form.idCmsScenes === undefined) {
        this.form.idCmsScenes = ['1']
      }
      const res = await this.$store.dispatch('http', {
        api: apiStr,
        params: this.form
      })
      console.log(res)
      if (res) {
        this.$store.dispatch('showToast', { type: 'success', message: '操作成功' })
        this.$emit('ok')
      }
    },
    setTreeFatherDisabled (alist, lever) {
      lever ? lever++ : lever = 1
      alist.map(item => {
        if (lever === 3) {
          item.disabled = true
        } else {
          item.disabled = false
          if (item.children) {
            item.children = this.setTreeFatherDisabled(item.children, lever)
          }
        }
      })
      return alist
    },
    async queryTreeData () {
      const res = await this.$store.dispatch('http', {
        api: 'navagationTree',
        params: { navigationType: 'cms' }
      })
      console.log(res, 'rrrsss')
      const treeSetDisabled = this.setTreeFatherDisabled(res)
      this.treeData = [this.fatherLan, ...treeSetDisabled]
    },
    handleChange () {
      console.log('handlechange')
    },
    resetFields () {
      console.log('resetFields')
      this.$emit('ok')
    }
  }
}
</script>

<style>

</style>
